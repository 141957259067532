import ConnectisLogo from 'assets/images/connectis-logo-v2-white.svg';
import { bottomFooterMenu, menuSchema, socials } from '../configs';
import {
  EllipseBottom,
  EllipseMid,
  EllipseTop,
  FooterBottomMenu,
  FooterMenu,
  FooterSocials,
} from './subcomponents';

const connectisWebsiteUrl = process.env.REACT_APP_CONNECTIS_WEBSITE_URL || '';
export const ConnectisWebsiteFooter = () => {
  return (
    <footer className="relative bg-gradient-blue py-6 pt-14 lg:pt-[100px] overflow-hidden light-section">
      <EllipseTop className="absolute top-0 left-0 z-0 hidden lg:block" />
      <EllipseMid className="absolute top-0 -right-28 sm:right-0 z-0" />
      <EllipseBottom className="absolute bottom-0 left-20 z-0" />
      <div className="job-board-container mx-auto z-10 relative">
        <div className="flex justify-between items-start flex-col lg:flex-row">
          <a className="mb-20" href={connectisWebsiteUrl}>
            <img alt="Connectis_" className="w-[8.8125rem] md:w-[18.75rem]" src={ConnectisLogo} />
          </a>
          <div className="flex flex-col md:flex-row gap-10 xl:gap-[3rem] w-full md:w-auto">
            {menuSchema.map((menu) => (
              <FooterMenu key={menu.name} {...menu} />
            ))}
          </div>
        </div>
        <div className="pt-6 mt-16 md:border-t border-light-blue-900 flex justify-between flex-col-reverse md:flex-row">
          <FooterSocials socials={socials} />
          <FooterBottomMenu bottomMenu={bottomFooterMenu} />
        </div>
      </div>
    </footer>
  );
};
