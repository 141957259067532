import { useScrollTop, useSitemap } from 'hooks';
import { ConnectisLogo, MobileMenuIcon } from 'icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { IconButton } from '../../Buttons';
import { getStartedUrl } from '../configs';
import { ConnectisWebsiteHeaderMenu, ConnectisWebsiteMobileHeaderMenu } from './subcomponents';
import {
  ConnectisWebsiteHeaderLink,
  ConnectisWebsiteHeaderSignInButton,
} from './subcomponents/ConnectisWebsiteHeaderMenu/subcomponents';

interface ConnectisWebsiteHeaderProps {
  isLightBackground: boolean;
}

const connectisWebsiteUrl = process.env.REACT_APP_CONNECTIS_WEBSITE_URL || '';
export const ConnectisWebsiteHeader: FC<ConnectisWebsiteHeaderProps> = ({
  isLightBackground = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:headerMenu',
  });
  const sitemap = useSitemap();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isScrollTop = useScrollTop();

  return (
    <header
      className={mergeClasses(
        'fixed top-0 left-0 w-screen z-40 transition-all duration-150',
        isLightBackground ? 'text-black' : 'text-white',
        isScrollTop
          ? 'backdrop-blur-2xl bg-white/10 xl:backdrop-blur-[unset] xl:bg-[unset]'
          : 'backdrop-blur-none xl:backdrop-blur-[unset]',
      )}
    >
      <div className="job-board-container mx-auto flex justify-between items-center">
        <a className="z-20" href={connectisWebsiteUrl}>
          <ConnectisLogo className="w-[120px]" />
        </a>
        <ConnectisWebsiteHeaderMenu isScrollTop={isScrollTop} />
        <ConnectisWebsiteMobileHeaderMenu
          onClose={() => setIsMobileMenuOpen(false)}
          open={isMobileMenuOpen}
        />
        <nav className="hidden xl:flex items-center space-x-6 z-20">
          <ConnectisWebsiteHeaderLink href={sitemap.jobBoard} label={t('jobOrders')} />
          <ConnectisWebsiteHeaderLink href={getStartedUrl} label={t('getStarted')} />
          <ConnectisWebsiteHeaderSignInButton />
        </nav>
        <IconButton
          className="xl:hidden text-neon-green-500 my-6 p-0 translate-y-1"
          hover={false}
          icon={MobileMenuIcon}
          iconClassName="w-6"
          onClick={() => setIsMobileMenuOpen(true)}
        />
      </div>
    </header>
  );
};
