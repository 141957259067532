import { JsonObject } from 'type-fest';

export const connectisWebsite: JsonObject = {
  hero: {
    jobOffers: 'Oferty pracy',
    title: 'Wybierz projekt \ni\u00A0dołącz do nas',
    subtitle:
      'Szukasz nowych wyzwań zawodowych? Sprawdź nasze oferty pracy\n i znajdź stanowisko idealne dla siebie. Dołącz do zespołu, który ceni innowacyjność i wspiera Twój rozwój.',
    trustedUs: 'Zaufali nam',
    ourPortfolio: 'Nasze portfolio',
  },
  hubSection: {
    title: 'Aplikuj z C_hub',
    description:
      'C_hub to platforma, która dopasuje oferty pracy na podstawie Twojego doświadczenia i preferencji.\n Przyspiesz proces rekrutacji i umów się na spotkanie!',
    joinHub: 'Dołącz do C_hub',
    startWithHub: 'Tutaj zaczynasz z C_hub',
    step: 'Krok',
    steps: [
      'Znalezienie oferty',
      'Przesłanie zgłoszenia',
      'Analiza zgłoszenia',
      'Rozmowa z Connectis',
      'Rozmowa techniczna',
      'Zatrudnienie',
    ],
  },
  menus: {
    ourServices: {
      heading: 'Nasze usługi',
      headingFooter: 'Oferta',
      items: {
        generativeAI: {
          label: 'Generative AI',
          description:
            'Przyspiesz obniżenie kosztów, zwiększenie efektywności i tworzenie nowych produktów.',
        },
        dataAndMLAI: {
          label: 'Data & ML/AI',
          description: 'Zmień swoje dane w przewagę konkurencyjną.',
        },
        applicationEngineeringServices: {
          label: 'Projektowanie oprogramowania',
          footerLabel: 'Rozwój oprogramowania',
          description:
            'Skróć czas realizacji projektów technologicznych i\xa0szybciej osiągaj swoje cele biznesowe.',
        },
        cloudServices: {
          label: 'Rozwiązania chmurowe',
          description: 'Przyspiesz wdrażanie innowacyjnych rozwiązań w\xa0chmurze.',
        },
        hireAnExpert: {
          label: 'Zatrudnij Eksperta',
        },
      },
    },
    companies: {
      heading: 'Dla firm',
      items: {
        hireAnExpert: {
          description:
            'Eksperci precyzyjnie wyselekcjonowani metodą 10\xa0punktów dopasowania® Connectis.',
          label: 'Zatrudnij Eksperta',
        },
        ourServices: {
          description: 'Połącz się z nami, aby zapewnić najlepsze wsparcie swoim projektom.',
          label: 'Nasze usługi',
        },
        iNeedAQuote: {
          label: 'Potrzebuję wyceny',
        },
      },
    },
    experts: {
      heading: 'Dla Ekspertów',
      items: {
        techExperts: {
          description: 'Oferty od globalnych firm idealnie dopasowane do Twoich oczekiwań.',
          label: 'Dla Ekspertów IT',
        },
        becomeConnectedExpert: {
          description: 'Dołącz do naszej społeczności najlepszych ekspertów i ekspertek IT.',
          label: 'Zostań Connected Expert®',
        },
        jobOffers: {
          description: 'Aktualne oferty projektów od uznanych globalnych firm.',
          label: 'Oferty pracy',
        },
        cHub: {
          label: 'Platforma Connectis',
          description: 'Przejrzystość i łatwość współpracy.',
        },
        referralProgram: {
          label: 'Program poleceń',
        },
      },
    },
    whyConnectis: {
      heading: 'Dlaczego Connectis',
      items: {
        caseStudies: {
          description: 'Wybrane projekty będące efektem pracy naszych ekspertek i ekspertów IT.',
          label: 'Case studies',
        },
        aboutUs: {
          description:
            'Doświadczeni i starannie wyselekcjonowani eksperci IT oraz najnowsze technologie.',
          label: 'O nas',
        },
        ambassadorProgram: {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in turpis rhoncus sem maximus feugiat id non orci.',
          label: 'Program ambasadorski',
        },
        contact: {
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          label: 'Kontakt',
        },
      },
    },
  },
  signInButton: {
    signIn: 'Zaloguj się',
    login: 'Logowanie',
    iAmExpert: 'Jestem ekspertem',
    iAmClient: 'Jestem klientem',
  },
  headerMenu: {
    jobOrders: 'Oferty pracy',
    getStarted: 'Get Started',
  },
  portfolioProject: {
    seeProject: 'Zobacz projekt',
    digitalTransformation: {
      title: 'Transformacja cyfrowa',
      description:
        'Wsparcie dedykowanym rozwiązaniem usprawniającym procesy i poprawiającym konkurencyjność',
    },
  },
  bottomMenu: {
    officeConnectis: 'office.pl@connectis.pl',
    privacy: 'Privacy',
    sitemap: 'Sitemap',
    termsOfService: 'Terms of service',
  },
};
