const connectisWebsiteUrl = process.env.REACT_APP_CONNECTIS_WEBSITE_URL || '';

export const bottomFooterMenu = [
  {
    labelKey: 'officeConnectis',
    url: 'mailto:office.pl@connectis.pl',
  },
  {
    labelKey: 'sitemap',
    url: `${connectisWebsiteUrl}connectis-sitemap`,
  },
  {
    labelKey: 'privacy',
    url: `${connectisWebsiteUrl}privacy`,
  },
];
